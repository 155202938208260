<template>
  <section id="editSlide">
    <b-tabs
      pills
      lazy
    >
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>{{ $t('slider.data') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="show"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>
                    {{ $t('slider.data') }}
                  </b-card-title>
                  <b-button
                    class="ls-1 text-white mb-1 mt-1"
                    variant="success"
                    @click="validationFormInfo($event)"
                  >
                    {{
                      $t("dataGeneric.save") }}
                  </b-button>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="12"
                      class="mb-2"
                    >
                      <b-form>
                        <validation-observer
                          ref="rulesSlide"
                          tag="form"
                        >
                          <b-row
                            v-if="language == base"
                            class="match-height mt-2"
                          >
                            <b-col md="6">
                              <b-form-group
                                :label="$t('dataGeneric.name')"
                                label-for="name"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="name"
                                  rules="required"
                                >
                                  <b-form-input
                                    id="name"
                                    v-model="name"
                                    autocomplete="new-password"
                                    :state="errors.length > 0 ? false : null"
                                    :placeholder="$t('slider.name')"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="1">
                              <b-form-group
                                :label="$t('dataGeneric.activeQ')"
                                label-for="name"
                              >
                                <b-form-checkbox
                                  id="patymentTerms"
                                  v-model="isActive"
                                  :disabled="isActive ? false : !activeSlide"
                                  switch
                                />
                              </b-form-group>
                            </b-col>
                            <b-col
                              v-if="!activeSlide"
                              style="align-self: center;"
                            >
                              <div class="active-info">
                                <p>{{ messageDeactive }}</p>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row class="match-height mt-2">
                            <b-col>
                              <b-form-group class="mb-2 mr-1">
                                <label class="d-flex ">
                                  {{ $t("Image") }}
                                  <feather-icon
                                    v-b-tooltip.hover.top.v-primary="$t('slider.imageSize')
                                    "
                                    class="ml-2"
                                    icon="InfoIcon"
                                  />
                                </label>
                                <b-row>
                                  <b-col
                                    @dragover="event => event.preventDefault()"
                                    @drop="addFiles($event, 'imageData' + language)"
                                  >
                                    <b-aspect :aspect="aspect">
                                      <span
                                        v-if="imageDataSrc == null &&
                                          imageDataShow == null
                                        "
                                        class="dropContainer placeholder embed-responsive-item"
                                        @click="chooseImage('imageData' + language)"
                                      >
                                        <feather-icon
                                          icon="ImageIcon"
                                          size="80"
                                        />
                                        {{ $t("media.dropFilesToCard") }}
                                      </span>
                                      <b-img
                                        v-else
                                        class="dropContainer cursor-pointer"
                                        fluid
                                        :src="imageDataSrc == null
                                          ? imageDataShow == null
                                            ? ''
                                            : imageDataShow
                                          : imageDataSrc
                                        "
                                        @click="chooseImage('imageData' + language)"
                                        @error="errorImg"
                                      />
                                    </b-aspect>
                                    <b-form-file
                                      :id="`imageData` + language"
                                      v-model="imageData"
                                      accept="image/*"
                                      class="mr-1"
                                      style="display: none"
                                      @change="event => getImageDimensions(event, 'web')"
                                    />
                                  </b-col>
                                </b-row>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group class="mb-2 mr-1">
                                <label class="d-flex">
                                  {{ $t('slider.imageForMobile') }}
                                  <feather-icon
                                    v-b-tooltip.hover.top.v-primary="$t('slider.imageSizeMobile')
                                    "
                                    class="ml-2"
                                    icon="InfoIcon"
                                  />
                                </label>
                                <b-row>
                                  <b-col
                                    @dragover="event => event.preventDefault()"
                                    @drop="addFiles($event, 'imageDataMobile' + language)"
                                  >
                                    <b-aspect :aspect="aspectMob">
                                      <span
                                        v-if="imageDataMobileSrc == null &&
                                          imageDataMobileShow == null
                                        "
                                        class="dropContainer placeholder embed-responsive-item"
                                        @click="chooseImage('imageDataMobile' + language)"
                                      >
                                        <feather-icon
                                          icon="ImageIcon"
                                          size="80"
                                        />
                                        {{ $t("media.dropFilesToCard") }}
                                      </span>

                                      <b-img
                                        v-else
                                        class="dropContainer cursor-pointer "
                                        fluid
                                        :src="imageDataMobileSrc == null
                                          ? imageDataMobileShow == null
                                            ? ''
                                            : imageDataMobileShow
                                          : imageDataMobileSrc
                                        "
                                        @click="chooseImage('imageDataMobile' + language)"
                                        @error="errorImg"
                                      />
                                    </b-aspect>
                                    <b-form-file
                                      :id="`imageDataMobile` + language"
                                      v-model="imageDataMobile"
                                      accept="image/*"
                                      class="mr-1"
                                      style="display: none"
                                      @change="event => getImageDimensions(event, 'mobile')"
                                    />
                                  </b-col>
                                </b-row>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <b-form-group
                            :label="language == base ? $t('slider.destiny') : ''"
                            label-for="slideType"
                          >
                            <b-row class="align-items-center mt-2">
                              <b-col
                                v-if="language == base"
                                md="3"
                                class="mr-2"
                              >
                                <h5>{{ $t('Destino') }}</h5>
                                <b-form-select
                                  id="slideType"
                                  v-model="slideType"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  label="text"
                                >
                                  <b-form-select-option value="URL">
                                    URL
                                  </b-form-select-option>
                                  <b-form-select-option value="EMAIL">
                                    {{ $t('Email') }}
                                  </b-form-select-option>
                                  <b-form-select-option value="TEL">
                                    {{ $t('finalUsers.Phone') }}
                                  </b-form-select-option>
                                  <b-form-select-option
                                    v-if="typeSlide != 'SHO' || sponsor"
                                    value="CON"
                                  >
                                    {{ $t("contents.content") }}
                                  </b-form-select-option>
                                  <b-form-select-option
                                    v-if="typeSlide != 'SHO' || sponsor"
                                    value="CAT"
                                  >
                                    {{ $t("purchases.category") }}
                                  </b-form-select-option>
                                  <b-form-select-option
                                    v-if="isShop"
                                    value="CAP"
                                  >
                                    {{ $t('slider.productCategory') }}
                                  </b-form-select-option>
                                  <b-form-select-option
                                    v-if="isShop"
                                    value="PRO"
                                  >
                                    {{ $t('producto') }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-col>
                              <b-col
                                v-if="slideType == 'URL'"
                                style="display: inline"
                              >
                                <validation-provider
                                  v-slot="validationContext"
                                  name="url"
                                  rules="required|url"
                                >
                                  <b-form-group
                                    label="url"
                                    label-for="url"
                                    :invalid-feedback="$t('required')"
                                  >
                                    <div class="d-flex justify-content-center align-items-center">
                                      <b-form-input
                                        id="url"
                                        v-model="url"
                                        autocomplete="new-password"
                                        :state="validationContext.errors.length > 0 ? false : null"
                                        placeholder="url"
                                      />
                                    </div>
                                    <small class="text-danger">{{ confirmRules(validationContext) }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col
                                v-if="slideType == 'EMAIL'"
                                style="display: inline"
                              >
                                <validation-provider
                                  v-slot="validationContext"
                                  name="email"
                                  rules="required|email"
                                >
                                  <b-form-group
                                    :label="$t('Email')"
                                    label-for="url"
                                  >
                                    <b-form-input
                                      id="email"
                                      v-model="email"
                                      autocomplete="new-password"
                                      maxlength="254"
                                      :state="validationContext.errors.length > 0 ? false : null"
                                      :placeholder="$t('Email')"
                                    />
                                    <small class="text-danger">{{ confirmRules(validationContext) }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col
                                v-if="slideType == 'TEL'"
                                style="display: inline"
                              >
                                <validation-provider
                                  v-slot="validationContext"
                                  name="tel"
                                  :rules="{ required: true, regex: /^\+?[1-9]\d{1,14}$/ }"
                                >
                                  <b-form-group
                                    :label="$t('finalUsers.Phone')"
                                    label-for="url"
                                  >
                                    <b-form-input
                                      id="tel"
                                      v-model="tel"
                                      autocomplete="new-password"
                                      :state="validationContext.errors.length > 0 ? false : null"
                                      :placeholder="$t('finalUsers.Phone')"
                                    />
                                    <small class="text-danger">{{ confirmRules(validationContext) }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col
                                v-if="slideType == 'CON' && language == base"
                                style="display: inline"
                              >
                                <h5 class="m-1">
                                  {{ $t('contents.content') }}
                                </h5>

                                <validation-provider
                                  v-slot="{ errors }"
                                  name="contentName"
                                  rules="required"
                                >
                                  <b-form-group
                                    label-for="contentName"
                                    :invalid-feedback="$t('required')"
                                  >
                                    <div
                                      class="d-flex justify-content-center align-items-center"
                                      @click="selectElement(0)"
                                    >
                                      <b-img
                                        id="avatar"
                                        class="mr-2"
                                        rounded="lg"
                                        :src="buildImageUrl(imageCnt)"
                                        @error="errorImg"
                                      />

                                      <b-form-input
                                        id="content"
                                        v-model="contentName"
                                        autocomplete="new-password"
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('modal.content')"
                                        disabled
                                      />

                                      <b-button
                                        class="ml-2"
                                        variant="info"
                                        @click="selectElement(0)"
                                      >
                                        {{ $t('Select') }}
                                      </b-button>
                                    </div>
                                    <small class="text-danger">{{ errors[0] ? $t('required') : '' }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>

                              <b-col
                                v-if="slideType == 'CAT' && language == base"
                                style="display: inline"
                              >
                                <h5 class="m-1">
                                  {{ $t('purchases.category') }}
                                </h5>

                                <validation-provider
                                  v-slot="{ errors }"
                                  name="category"
                                  rules="required"
                                >
                                  <b-form-group
                                    label-for="category"
                                    :invalid-feedback="$t('required')"
                                  >
                                    <div
                                      class="d-flex justify-content-center align-items-center"
                                      @click="selectElement(1)"
                                    >
                                      <b-img
                                        id="avatar"
                                        class="mr-2 "
                                        rounded="lg"
                                        :src="buildImageUrl(imageCat)"
                                        @error="errorImg"
                                      />

                                      <b-form-input
                                        v-model="categoryName"
                                        autocomplete="new-password"
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('modal.category')"
                                        disabled
                                      />
                                      <b-button
                                        class="ml-2"
                                        variant="info"
                                        @click="selectElement(1)"
                                      >
                                        {{ $t('Select') }}
                                      </b-button>
                                    </div>
                                    <small class="text-danger">{{ errors[0] ? $t('required') : '' }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>

                              <b-col
                                v-if="slideType == 'CAP' && language == base"
                                style="display: inline"
                              >
                                <h5>
                                  {{ $t('slider.productCategory') }}
                                </h5>

                                <validation-provider
                                  v-slot="{ errors }"
                                  name="categoryProduct"
                                  rules="required"
                                >
                                  <b-form-group
                                    label-for="categoryProduct"
                                    :invalid-feedback="$t('required')"
                                  >
                                    <div
                                      class="d-flex justify-content-center align-items-center"
                                      @click="selectElement(2)"
                                    >
                                      <b-img
                                        id="avatar"
                                        class="mr-2"
                                        rounded="lg"
                                        :src="buildImageUrl(imageCatProd)"
                                        @error="errorImg"
                                      />

                                      <b-form-input
                                        v-model="categoryProdName"
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('slider.productCategory')"
                                        autocomplete="new-password"
                                        disabled
                                      />
                                      <b-button
                                        variant="info"
                                        class="ml-2"
                                        @click="selectElement(2)"
                                      >
                                        {{ $t('Select') }}
                                      </b-button>
                                    </div>
                                    <small class="text-danger">{{ errors[0] ? $t('required') : '' }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                              <b-col
                                v-if="slideType == 'PRO' && language == base"
                                style="display: inline"
                              >
                                <h5>
                                  {{ $t('producto') }}
                                </h5>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="product"
                                  rules="required"
                                >
                                  <b-form-group
                                    label-for="product"
                                    :invalid-feedback="$t('required')"
                                  >
                                    <div
                                      class="d-flex justify-content-center align-items-center"
                                      @click="selectElement(3)"
                                    >
                                      <b-img
                                        id="avatar"
                                        class="mr-2"
                                        rounded="lg"
                                        :src="buildImageUrl(imageProd)"
                                        @error="errorImg"
                                      />
                                      <b-form-input
                                        id="product"
                                        v-model="productName"
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('producto')"
                                        autocomplete="new-password"
                                        disabled
                                      />
                                      <b-button
                                        variant="info"
                                        class="ml-2"
                                        @click="selectElement(3)"
                                      >
                                        {{ $t('Select') }}
                                      </b-button>
                                    </div>
                                    <small class="text-danger">{{ errors[0] ? $t('required') : '' }}</small>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                            </b-row>
                          </b-form-group>
                        </validation-observer>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="language == base">
        <template #title>
          <feather-icon icon="CalendarIcon" />
          <span>{{ $t("editContent.status") }}</span>
        </template>
        <b-overlay
          variant="transparent"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
          :show="showState"
        >
          <b-card>
            <b-card-header>
              <b-card-title>{{ $t("editContent.status") }}</b-card-title>
              <b-button
                variant="success"
                @click="updateStateSlide"
              >
                {{
                  $t("dataGeneric.save")
                }}
              </b-button>
            </b-card-header>
            <b-card-body>
              <b-row>
                <b-col
                  lg="12"
                  class="ml-2 mb-2"
                >
                  <b-form>
                    <b-form-row>
                      <b-col>
                        <b-form-group class="mb-2 mr-1">
                          <label class="mb-1">
                            {{ $t('contents.datePublication') }}
                            <feather-icon
                              v-if="publishDate"
                              class="text-danger cursor-pointer ml-1"
                              icon="XOctagonIcon"
                              size="15"
                              @click="publishDate = null"
                            />
                          </label>
                          <flat-pickr
                            v-model="publishDate"
                            class="form-control"
                            :placeholder="$t('contents.datePublication')"
                            :config="{
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'Y-m-d H:i:S',
                              minDate: 'today'
                            }"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group class="mb-2 mr-1">
                          <label class="mb-1">
                            {{ $t('contents.dateFinishing') }}
                            <feather-icon
                              v-if="expirationDate"
                              class="text-danger cursor-pointer ml-1"
                              icon="XOctagonIcon"
                              size="15"
                              @click="expirationDate = null"
                            />
                          </label>
                          <flat-pickr
                            v-model="expirationDate"
                            class="form-control"
                            :placeholder="$t('contents.dateFinishing')"
                            :config="{
                              enableTime: true,
                              enableSeconds: true,
                              dateFormat: 'Y-m-d H:i:S',
                              minDate: publishDate
                            }"
                          />
                        </b-form-group>
                      </b-col>
                    </b-form-row>
                  </b-form>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-tab>
      <b-tab v-if="sponsor && hasAdSlides">
        <template #title>
          <feather-icon icon="CreditCardIcon" />
          <span>{{ $t('Adslide') }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="show"
            >
              <b-card>
                <b-card-header>
                  <b-card-title>
                    {{ $t('configAdSlide') }}
                  </b-card-title>
                  <b-button
                    class="ls-1 text-white mb-1 mt-1"
                    variant="success"
                    @click="validationFormAd($event)"
                  >
                    {{
                      $t("dataGeneric.save") }}
                  </b-button>
                </b-card-header>
                <b-card-body>
                  <validation-observer
                    ref="rulesAdSlide"
                    tag="form"
                  >
                    <b-row>
                      <b-col>
                        <validation-provider
                          v-slot="validationContext"
                          name="positiurlImpresson"
                          rules="required"
                        >
                          <b-form-group :invalid-feedback="$t('required')">
                            <label>{{ $t('urlImpress') }}</label>
                            <b-form-input
                              id="urlImpress"
                              v-model="callbackImpressionsUrl"
                              :state="validationContext.errors.length > 0 ? false : null"
                              :placeholder="$t('urlImpress')"
                              autocomplete="new-password"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- <b-col md="6">
                        <validation-provider
                          v-slot="validationContext"
                          name="position"
                          rules="required|min_value:0"
                        >
                          <b-form-group :invalid-feedback="$t('required')">
                            <label>{{ $t('position') }}</label>
                            <b-form-input
                              id="position"
                              v-model="position"
                              type="number"
                              disabled
                              :state="validationContext.errors.length > 0 ? false : null"
                              :placeholder="$t('position')"
                              autocomplete="new-password"
                              @change="checkPercentage()"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col> -->
                      <b-col md="6">
                        <validation-provider
                          v-slot="validationContext"
                          name="impressions"
                          rules="required|min_value:0"
                        >
                          <b-form-group :invalid-feedback="$t('required')">
                            <label>{{ $t('code.impresiones') }}<feather-icon
                              v-b-tooltip.hover.top.v-primary="$t('code.impresionesInfo')
                              "
                              class="ml-1"
                              icon="InfoIcon"
                            /></label>
                            <b-form-input
                              id="impressions"
                              v-model="impressions"
                              :state="validationContext.errors.length > 0 ? false : null"
                              type="number"
                              :placeholder="$t('code.impresiones')"
                              autocomplete="new-password"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <label>{{ $t('code.consumed') }}</label>
                          <b-form-input
                            id="consumed"
                            v-model="consumed"
                            disabled
                            type="number"
                            :placeholder="$t('code.consumed')"
                            autocomplete="new-password"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-form-group>
                      <label class="d-flex align-items-center justify-content-between mt-1">
                        {{ $t('percentageRotation') }}
                        <b-button
                          variant="primary"
                          @click="applyPercentage()"
                        >
                          {{ $t('aplicar') }}
                        </b-button></label>
                      <b-row class="m-1">
                        <b-col
                          v-for="(adSlide, i) in allAdSlide"
                          :key="adSlide.node.id"
                        >
                          <label>{{ adSlide.node.name }}<feather-icon
                            v-b-tooltip.hover.top.v-primary="$t('infoPercentage')
                            "
                            class="ml-1"
                            icon="InfoIcon"
                          /></label>
                          <div class="range-slider-container">
                            <label
                              :key="key"
                              :for="'range-' + i"
                              class="d-flex align-items-center"
                            ><b-form-input
                              v-model="adSlide.node.percentageRotation"
                              class="mr-1"
                              type="number"
                              step="0.01"
                              min="0"
                              max="100"
                            />%</label>
                            <b-form-input
                              :id="'range-' + i"
                              v-model="adSlide.node.percentageRotation"
                              type="range"
                              min="0"
                              max="100"
                              class="custom-range range-input"
                              @input="saveRange(adSlide.node)"
                            />
                            <div class="range-labels">
                              <span>0</span>
                              <span>50</span>
                              <span>100</span>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </validation-observer>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <!-- GEOBLOQUEO -->
      <b-tab v-if="geoipbloking && language == base">
        <template #title>
          <feather-icon icon="MapIcon" />
          <span>{{ $t('zones.title') }}</span>
        </template>
        <b-card>
          <geoblocking :type-element="'SLD'" />
        </b-card>
      </b-tab>
    </b-tabs>
    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('modal.content')"
    >
      <div class="d-block text-center">
        <content-selector
          @info="SelectContentInfo"
          @image="SelectContentImage"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="cat-modal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="categorySelectorTable"
          @confirm-selection="CategorySelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="cat-prod-modal"
      size="xl"
      hide-footer
      :title="$t('slider.selectShopCategory')"
    >
      <div class="d-block text-center">
        <category-product-selector
          :columns="categorySelectorTable"
          @confirm-selection="CategoryProductSelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="prod-modal"
      size="xl"
      hide-footer
      :title="$t('slider.selectProduct')"
    >
      <div class="d-block text-center">
        <product-selector
          :columns="categorySelectorTable"
          @confirm-selection="ProductSelection"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import {
  BForm,
  BRow,
  BTabs,
  BTab,
  BCol,
  BFormRow,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormGroup,
  BCard,
  BImg,
  BFormFile,
  BCardHeader,
  BCardBody,
  VBTooltip,
  BAspect,
  BCardTitle,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, camelToSnake, showToast, base64Encode, camelToKebab,
  limitCharacter,
  isEmpty,
} from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { getUserData } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
import geoblocking from '@/views/components/geoblocking.vue'

import ProductSelector from '../../../common/ProductSelector.vue'
import CategoryProductSelector from '../../../common/CategoryProductSelector.vue'

import ContentSelector from '../../../common/ContentSelector.vue'
import CategorySelector from '../../../common/CategorySelector.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BCardHeader,
    BOverlay,
    BAspect,
    BCardBody,
    BFormRow,
    CategorySelector,
    flatPickr,
    BCardTitle,
    ContentSelector,
    ProductSelector,
    geoblocking,
    BTabs,
    BTab,
    CategoryProductSelector,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BCard,
    BImg,
    BFormFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showState: false,
      publishDate: null,
      expirationDate: null,
      impressions: 0,
      consumed: 0,
      percentageRotation: 0,
      percentageRotationDB: 0,
      position: 0,
      callbackImpressionsUrl: '',
      sponsor: false,
      hasAdSlides: false,
      geoipbloking: false,
      required,
      viewName: this.$t('slider.edit'),
      viewDetail: this.$t('slider.subtitle'),
      slide: null,
      userData: getUserData(),
      type: {
        SHO: this.$t('Shop'),
        CON: this.$t('contents.content'),
        URL: 'URL',
        CAT: this.$t('purchases.category'),
      },
      slideType: null,
      isShop: false,
      allAdSlide: [],
      name: null,
      isActive: false,
      url: null,
      contentName: null,
      categoryProdName: '',
      categoryProducts: '',
      product: '',
      productName: '',
      categoryId: '',
      imageDataShow: null,
      imageData: null,
      aspect: 1920 / 575,
      aspectMob: 1170 / 804,
      show: false,
      id: '',
      imageDataSrc: null,
      selectedType: 'CON',
      categoryName: null,
      elementId: null,
      imageDataSrcBol: false,
      catTableData: null,
      categorySelectorTable: [
        { key: 'image', label: this.$t('contents.category') },
        { key: 'name', label: this.$t('firstName') },
      ],
      imageDataMobile: null,
      imageDataMobileSrc: null,
      imageDataMobileSrcBol: null,
      imageDataMobileShow: null,
      imageCnt: null,
      imageCat: null,
      key: 0,
      imageCatProd: null,
      imageProd: null,
      typeSlide: this.$route.params.type,
      activeSlide: false,
      messageDeactive: '',
      idSlider: null,
      originalStatus: false,
      email: null,
      tel: null,
    }
  },
  watch: {

    slideType() {
      switch (this.slideType) {
        case 'CAT':
        case 'CON':
          this.activeSlide = this.originalStatus
          this.activeMenu = this.originalStatus
          break
        default:
          this.activeSlide = true
          break
      }
    },
    imageData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataSrcBol = true
              this.imageDataSrc = value
            })
            .catch(() => {
              this.imageDataSrc = null
            })
        } else {
          this.imageDataSrc = null
        }
      }
    },
    imageDataMobile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataMobileSrcBol = true
              this.imageDataMobileSrc = value
            })
            .catch(() => {
              this.imageDataMobileSrc = null
            })
        } else {
          this.imageDataMobileSrc = null
        }
      }
    },
  },
  mounted() {
    extend('min_value', {
      validate(value, { min }) {
        return value >= min
      },
      params: ['min'],
      message: this.$t('messageAdv', { min: '{min}' }),
    })
    try {
      this.isShop = this.userData.profile.client.isShop
      this.hasAdSlides = this.userData.profile.client.hasAdSlides

      // eslint-disable-next-line no-empty
    } catch (error) {
    }
    this.geoipbloking = this.userData?.profile?.client?.geoipbloking

    this.getData()
    this.allCategory()
  },
  methods: {
    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
    updateStateSlide(event) {
      this.showState = true

      event.preventDefault()
      const { id } = this.$route.params
      const dateIn = this.fechaFormato(this.publishDate)
      const dateFin = this.fechaFormato(this.expirationDate)

      axios
        .post('', {
          variables: {
            publishDate: dateIn,
            expirationDate: dateFin,
          },
          query: `
         
          mutation($publishDate: DateTime,$expirationDate: DateTime){
            updateSlide(id:"${id}", input:{
              isActive:${this.isActive},
              publishDate: $publishDate,
              expirationDate: $expirationDate,
              }){
                  slide{
                  id
                  name
                  publishDate
                  expirationDate
              }
            }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(
            this.$t('code.cat', {
              data: res.data.data.updateSlide.slide.name,
            }),
            1, this,
          )

          this.getData()

          this.showState = false
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.updateDataError'), 2, this)
          this.showState = false
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    getData() {
      const { id } = this.$route.params
      axios
        .post('', {
          query: `
          query{
  allSlide(id: "${id}", client: "${this.userData.profile.client.id}"){
                edges {
                node {
        id
        name
        imageUrl(lang: "${this.language}")
        imageMobileUrl(lang: "${this.language}")
        slideType
        isActive
        url(lang: "${this.language}")
        categoryProducts
        product
        publishDate
        expirationDate
                    slide {
                      edges {
                        node {
              id
            }
          }
        }
                    elementId{
          id
          name
          imageUrl
          state
        }
                    categoryId{
          id
          name
          imageUrl
          isActive
          state
        }
                    adslide {
          id
          impressions
          consumed
          percentageRotation
          sponsor
          position
          callbackImpressionsUrl
        }
      }
    }
  }
}
`,
        })
        .then(res => {
          messageError(res, this)
          this.slide = res.data.data.allSlide.edges[0].node
          this.name = this.slide.name
          this.slideType = this.slide.slideType
          this.isActive = this.slide.isActive
          this.url = this.slide.url
          this.imageDataShow = this.slide.imageUrl !== '' ? this.slide.imageUrl : null
          this.imageDataSrcBol = this.imageDataShow != null
          this.idSlider = this.slide.slide.edges[0]?.node.id
          this.imageDataMobileShow = this.slide.imageMobileUrl !== '' ? this.slide.imageMobileUrl : null
          this.imageDataMobileSrcBol = this.imageDataMobileShow != null
          this.adslide = this.slide?.adslide
          this.publishDate = this.fechaFormato(this.slide.publishDate)
          this.expirationDate = this.fechaFormato(this.slide.expirationDate)
          if (this.adslide) {
            this.impressions = this.adslide.impressions
            this.consumed = this.adslide.consumed
            this.percentageRotation = this.adslide.percentageRotation
            this.percentageRotationDB = this.adslide.percentageRotation
            this.sponsor = this.adslide.sponsor
            this.position = this.adslide.position
            this.callbackImpressionsUrl = this.adslide.callbackImpressionsUrl
          }
          if (this.url) {
            if (this.url.startsWith('mailto:')) {
              this.slideType = 'EMAIL'
              this.email = this.url.replace(/^mailto:/, '')
              this.url = ''
              this.tel = ''
            } else if (this.url.startsWith('tel:')) {
              this.slideType = 'TEL'
              this.tel = this.url.replace(/^tel:/, '')
              this.url = ''
              this.email = ''
            }
          }
          try {
            this.contentName = this.slide.elementId.name
            this.elementId = this.slide.elementId.id
            this.imageCnt = this.slide.elementId.imageUrl
            const state = this.slide.elementId.state === 'GRE'

            if (this.slideType === 'CON') {
              this.activeSlide = state
              this.messageDeactive = this.$t('deacCont')
            } else this.activeSlide = true
            // eslint-disable-next-line no-empty
          } catch (error) { }

          try {
            this.categoryName = this.slide.categoryId.name
            this.categoryId = this.slide.categoryId.id
            this.imageCat = this.slide.categoryId.imageUrl
            const state = this.slide.categoryId.state === 'GRE'
            if (this.slideType === 'CAT') {
              this.activeSlide = state
              this.messageDeactive = this.$t('deacCateg')
            } else this.activeSlide = true
            // eslint-disable-next-line no-empty
          } catch (error) { }
          this.originalStatus = this.activeSlide

          this.categoryProducts = this.slide.categoryProducts
          this.product = this.slide.product
          this.checkPercentage()
          this.getProduct()
          this.getCatProd()
          this.$emit('return', {
            name: this.name, viewName: this.viewName, viewDetail: this.viewDetail, state: this.isActive ? 'GRE' : 'RED',
          })
        })
    },
    getProduct() {
      axios
        .post('', {
          query: `
          query{
  allProducts(id: "${this.product}", client: "${this.userData.profile.client.id}"){
                edges {
                node {
        id
        name
        imageUrl

      }
    }
  }
}
`,
        })
        .then(res => {
          messageError(res, this)

          this.productName = res.data.data.allProducts.edges[0].node.name
          this.imageProd = res.data.data.allProducts.edges[0].node.imageUrl
        }).catch(() => {

        })
    },
    getImageDimensions(event, type) {
      let maxWidth = ''
      let maxHeight = ''

      if (type === 'web') {
        maxWidth = 1920
        maxHeight = 575
      } else {
        maxWidth = 1170
        maxHeight = 804
      }

      const file = event.target.files[0]
      if (!file) return

      const reader = new FileReader()
      reader.onload = e => {
        const img = new Image()
        img.onload = () => {
          const { width } = img
          const { height } = img

          if (width !== maxWidth || height !== maxHeight) {
            this.$swal({
              title: this.$t('warning'),
              text: this.$t('recomendedSize', {
                width, height, maxWidth, maxHeight,
              }),
              icon: 'warning',
              confirmButtonText: this.$t('next'),
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        }
        img.src = e.target.result
      }
      reader.readAsDataURL(file)
    },
    getCatProd() {
      axios
        .post('', {
          query: `
          query{
  allCategoryShop(id: "${this.categoryProducts}", client: "${this.userData.profile.client.id}"){
                edges {
                node {
        id
        name
        imageUrl

      }
    }
  }
}
`,
        })
        .then(res => {
          messageError(res, this)

          this.categoryProdName = res.data.data.allCategoryShop.edges[0].node.name
          this.imageCatProd = res.data.data.allCategoryShop.edges[0].node.imageUrl
        }).catch(() => {

        })
    },

    updateSlide(event) {
      this.show = true
      event.preventDefault()
      const headers = {
        'Accept-Language': camelToKebab(this.language),
        'Content-Language': camelToKebab(this.language),
      }

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()

      switch (this.slideType) {
        case 'URL':
          this.elementId = ''
          this.categoryId = ''
          break
        case 'CON':
          this.url = ''
          this.categoryId = ''
          break
        case 'CAT':
          this.elementId = ''
          this.url = ''
          break
        case 'CAP':
          this.elementId = ''
          this.categoryId = ''
          this.url = ''
          this.product = ''
          break
        case 'PRO':
          this.elementId = ''
          this.categoryId = ''
          this.url = ''
          this.categoryProducts = ''
          break
        default:
          break
      }
      let objectVariables = []
      if (this.slideType === 'EMAIL') this.url = `mailto:${this.email}`
      else if (this.slideType === 'TEL') this.url = `tel:${this.tel}`
      if (this.url) {
        objectVariables = [
          { title: `url${this.language}`, value: limitCharacter(this.url, 1000), type: 'String' },
        ]
      }

      if (this.language === this.base) {
        objectVariables.push({ title: 'name', value: limitCharacter(this.name, 150), type: 'String' })
        if (this.elementId) {
          objectVariables.push({ title: 'elementId', value: this.elementId, type: 'ID' })
        }
        if (this.categoryId) {
          objectVariables.push({ title: 'categoryId', value: this.categoryId, type: 'ID' })
        }
        if (this.product) {
          objectVariables.push({ title: 'product', value: this.product, type: 'String' })
        }
        if (this.categoryProducts) {
          objectVariables.push({ title: 'categoryProducts', value: this.categoryProducts, type: 'String' })
        }
        objectVariables.push({ title: 'isActive', value: this.isActive, type: 'Boolean' })

        const slideType = this.slideType === 'TEL' || this.slideType === 'EMAIL' ? 'URL' : this.slideType
        objectVariables.push({ title: 'slideType', value: slideType, type: 'ContentsSlideSlideTypeChoices' })
      }

      const variables = {}
      let mutation = 'mutation('
      let query = `{updateSlide(id:"${this.$route.params.id}",input:{\n
        `
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'

      if (isEmpty(objectVariables)) {
        mutation = 'mutation'
      }

      query += `}){
        slide{
                id
                name
                url
            }
        }
      }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      if (this.imageData != null) data.append(`image_${camelToSnake(this.language)} `, this.imageData)
      if (this.imageDataMobile != null) data.append(`image_mobile_${camelToSnake(this.language)} `, this.imageDataMobile)

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this).then(result => {
            if (!result) {
              this.getData()
              showToast(this.$t('code.updateData'), 1, this)
            }
          }).catch(() => {

          })
          this.show = false
        })
        .catch(res => {
          console.log(res)
          this.show = false

          showToast(this.$t('code.updateDataError'), 0, this)
        })
    },
    updateAdSlide(event) {
      this.show = true
      event.preventDefault()
      let query = 'mutation{'
      this.allAdSlide.forEach((element, i) => {
        query += `
            adSlide_${i + 1}:updateAdSlide(id:"${element.node.id}",input:{
              percentageRotation:${element.node.percentageRotation},
              ${this.$route.params.id === element.node.id ? ` impressions:${this.impressions},` : ''}
              ${this.$route.params.id === element.node.id ? `callbackImpressionsUrl:"${this.callbackImpressionsUrl}",` : ''}
              }){
              adSlide{
    name
    id
    url
  }
}
`
      })

      query += `           
          }`

      axios
        .post('', { query })
        .then(res => {
          messageError(res, this).then(result => {
            if (!result) {
              showToast(this.$t('code.updateData'), 1, this)
              this.getData()
            }
          }).catch(() => {

          })
          this.show = false
        })
        .catch(res => {
          console.log(res)
          this.show = false

          showToast(this.$t('code.updateDataError'), 0, this)
        })
    },
    selectElement(elemento) {
      switch (elemento) {
        case 0:
          this.$refs['my-modal'].show()
          break
        case 1:
          this.$refs['cat-modal'].show()

          break
        case 2:
          this.$refs['cat-prod-modal'].show()

          break
        case 3:
          this.$refs['prod-modal'].show()

          break

        default:
          break
      }
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.$refs['cat-modal'].hide()
      this.$refs['cat-prod-modal'].hide()
      this.$refs['prod-modal'].hide()
    },
    SelectContentImage(data) {
      this.imageCnt = data
      this.hideModal()
    },
    SelectContentInfo(data) {
      const [id, name, state] = data
      this.elementId = id
      this.contentName = name
      const stateBool = state === 'GRE'
      if (this.slideType === 'CON') {
        this.activeSlide = stateBool
        this.messageDeactive = this.$t('deacCont')
      } else this.activeSlide = true

      this.originalStatus = this.activeSlide

      this.isActive = stateBool ? this.isActive : false

      this.hideModal()
    },
    saveRange(node) {
      let percentages = []
      const { id } = node
      const percentageRotation = parseFloat(parseFloat(node.percentageRotation).toFixed(2))
      percentages = this.adjustPercentages(this.allAdSlide, id, percentageRotation)
      percentages.forEach(element => {
        const slide = this.allAdSlide.find(s => s.node.id === element.node.id)
        if (slide) {
          slide.node.percentageRotation = parseFloat(parseFloat(element.node.percentageRotation).toFixed(2))
        }
      })
      this.key += 1
    },
    applyPercentage() {
      this.allAdSlide.forEach(element => {
        this.saveRange(element.node)
      })
    },
    CategorySelection(data) {
      this.categoryName = data.node.name
      this.categoryId = data.node.id
      this.imageCat = data.node.imageUrl
      const state = data.node.state === 'GRE'
      if (this.menuType === 'CAT') this.activeSlide = state
      else this.activeSlide = true
      this.originalStatus = this.activeSlide

      this.messageDeactive = this.$t('deacCateg')
      this.isActive = data.node.isActive ? this.isActive : false

      // console.log(data);
      this.hideModal()
    },
    allCategory() {
      axios
        .post('', {
          query: `
            query{
  allCategories(client: "${this.userData.profile.client.id}"){
              edges{
                node{
        id
        name
        image
        mediaLocation
        imageUrl
      }
    }
  }
}
`,
        })
        .then(res => {
          messageError(res, this)

          this.catTableData = res.data.data.allCategories.edges
        })
    },
    chooseImage(imageClicked) {
      // console.log(imageClicked);
      //   this.imageClicked = imageClicked;
      document.getElementById(imageClicked).click()
    },
    goBack() {
      this.$router.push({ name: 'slider' })
    },
    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },
    validationFormInfo(event) {
      return new Promise(resolve => {
        this.$refs.rulesSlide.validate().then(success => {
          if (success) {
            resolve(true)
            this.updateSlide(event)
          } else {
            // reject();
          }
        })
      })
    },
    validationFormAd(event) {
      return new Promise(resolve => {
        this.$refs.rulesAdSlide.validate().then(success => {
          if (success) {
            resolve(true)
            this.updateAdSlide(event)
          } else {
            // reject();
          }
        })
      })
    },
    confirmRules(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('required')
      }
      if (existeClave.includes('url')) {
        return this.$t('tabUser.urlValid')
      }
      if (existeClave.includes('email')) {
        return this.$t('tabUser.emailValid')
      }
      if (existeClave.includes('regex')) {
        return this.$t('tabUser.telValid')
      }
      return ''
    },
    checkPercentage() {
      const query = `{
  allAdSlide(position: ${this.position}, slide: "${this.idSlider}"){
    totalCount
              edges {
                node {
        id
        name
        percentageRotation
                  slidePtr {
                    slide {
                      edges {
                        node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
} `
      axios
        .post('', {
          query,
        }).then(res => {
          messageError(res, this)
          const { allAdSlide } = res.data.data
          console.log(allAdSlide)
          this.allAdSlide = allAdSlide.edges
        })
    },
    adjustPercentages(adSlide, elementId, newElementPercentage) {
      const elementIndex = adSlide.findIndex(element => element.node.id === elementId)
      const updatedSlide = adSlide.map(element => ({
        ...element,
        node: { ...element.node },
      }))
      updatedSlide[elementIndex].node.percentageRotation = newElementPercentage

      const remainingPercentage = 100 - newElementPercentage

      if (remainingPercentage < 0) {
        updatedSlide[elementIndex].node.percentageRotation = 100
        return updatedSlide.map((element, index) => {
          if (index !== elementIndex) {
            return {
              ...element,
              node: {
                ...element.node,
                percentageRotation: 0,
              },
            }
          }
          return element
        })
      }
      const otherElementsTotalPercentage = updatedSlide.reduce((sum, element, index) => {
        if (index !== elementIndex) {
          return sum + parseFloat(element.node.percentageRotation || 0) // Asegúrate de convertir a número
        }
        return sum
      }, 0)

      const adjustedSlide = updatedSlide.map((element, index) => {
        if (index !== elementIndex) {
          return {
            ...element,
            node: {
              ...element.node,
              percentageRotation: (element.node.percentageRotation / otherElementsTotalPercentage) * remainingPercentage,
            },
          }
        }
        return element
      })

      return adjustedSlide
    },
    ProductSelection(data) {
      this.productName = data.node.name
      this.product = data.node.id
      this.imageProd = data.node.imageUrl

      this.hideModal()
    },
    addFiles(event, type) {
      event.dataTransfer.files.forEach(element => {
        switch (type) {
          case `imageData${this.language} `:
            this.imageData = element
            break
          case `imageDataMobile${this.language} `:
            this.imageDataMobile = element
            break

          default:
            break
        }
      })
      event.preventDefault()
    },
    CategoryProductSelection(data) {
      this.categoryProdName = data.node.name
      this.categoryProducts = data.node.id
      this.imageCatProd = data.node.imageUrl
      this.hideModal()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#editSlide .fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  #editSlide .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  #editSlide .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #editSlide .fullscreen-modal .modal-dialog {
    width: 1170px;
  }
}

#editSlide .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#editSlide .card-body h4 {
  font-size: 1.286rem !important;
}

#editSlide .col-form-label {

  text-align: center;
}

#editSlide .rowlogos {
  display: inline-flex;
}

#editSlide .div {
  display: inline-block;
}

#inputColor {
  visibility: hidden;
}

#editSlide .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#editSlide .placeholder:hover {
  background: #e0e0e0;
}

#editSlide .file-input {
  display: none;
}

#editSlide .cBox {
  align-items: center;
}

#editSlide .save-button {
  margin-top: 1rem;
  margin-left: 1rem;
}

#editSlide .custom-control-label {
  font-size: 15px;
}

#editSlide .dropContainer {
  border: 3px dashed;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#editSlide .active-info {
  display: flex;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

.range-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.range-input {
  width: 100%;
  margin: 20px 0;
  -webkit-appearance: none;
  background: transparent;
}

.range-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: #7367f0;
  border-radius: 5px;
}

.range-input::-webkit-slider-thumb {
  border: 1px solid #000000;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}

.range-input:focus::-webkit-slider-runnable-track {
  background: #7367f0;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;
}
</style>
